import PropTypes from 'prop-types'

import Content from './components/Content'
import MobileContent from './components/MobileContent'
import * as S from './styles'

const ALLOWED_TYPES = ['a', 'br', 'li', 'strong', 'ul']

const BlocTabs = ({ supTitle, tabs, title }) => (
  <S.Grid>
    <S.BlocTabs>
      {supTitle && <S.SupTitle>{supTitle}</S.SupTitle>}
      <S.Title>{title}</S.Title>

      <Content allowedTypes={ALLOWED_TYPES} tabs={tabs} />

      <MobileContent allowedTypes={ALLOWED_TYPES} tabs={tabs} />
    </S.BlocTabs>
  </S.Grid>
)

BlocTabs.propTypes = {
  supTitle: PropTypes.string,
  tabs: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
}

export default BlocTabs

import { breakpoints, g } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'
import * as typo from '@pretto/bricks/core/typography'
import styled from 'styled-components'

export const Grid = styled.div`
  ${grid()}
  margin: ${g(4)} 0;

`
export const BlocTabs = styled.div`
  ${column([2, 4])};
  background-color: ${({ theme }) => theme.colors.neutral4};
  padding: ${g(5)} ${g(3, -4)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([2, 8])};
    padding: ${g(6)} ${g(5)};
  }
  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([3, 10])};
    padding: ${g(6)} ${g(5)};
  }
`
export const SupTitle = styled.h3`
  ${typo.headline16};
  margin-bottom: ${g(2)};
`
export const Title = styled.h2`
  ${typo.heading32};
  margin-bottom: ${g(5)};
`
